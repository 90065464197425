import { LockReasonType, PortType } from 'api-schema/lib/model';
import { ReactElement } from 'react';
import { ReactComponent as StopIcon } from '../../../assets/img/stop-large-red.svg';
import { isNever } from '../../../utils/isNever';
import { Text } from '../Text';
import {
  LockMessage,
  StationLockedContainer,
  TextContainer,
} from './StationLocked.elements';

export type StationLockedProps = {
  portId: number;
  portType: PortType;
  reason?: LockReasonType;
};

export function StationLocked({
  portId,
  portType,
  reason,
}: StationLockedProps): ReactElement {
  return (
    <StationLockedContainer>
      <StopIcon />
      <TextContainer>
        <Text variant="h3" weight="medium" tag="span">
          This port has been locked.
        </Text>
        {reason && (
          <LockMessage variant="body1" weight="regular" tag="span">
            <Text variant="body1" weight="medium" tag="strong">
              Reason:{' '}
            </Text>
            {getReasonStringFromReason(reason)}
          </LockMessage>
        )}
        <Text variant="body2" weight="regular" tag="span">
          {getPortStringFromPortType(portType)} {portId}
        </Text>
      </TextContainer>
    </StationLockedContainer>
  );
}

const getPortStringFromPortType = (portType: PortType): string => {
  switch (portType) {
    case 'PICK':
      return 'Pick port';
    case 'PUTAWAY':
      return 'Putaway port';
    default:
      return 'Unknown port';
  }
};

const getReasonStringFromReason = (reason: LockReasonType): string => {
  switch (reason) {
    case 'MANUAL_LOCK':
      return 'Manually locked via API.';
    case 'TASK_GROUP_ASSIGNED_WHEN_PICKLISTS_PRESENT':
      return 'A new task group was assigned with picks still in progress. Manual intervention required.';
    case 'ABNORMAL_PICK_QUANTITY':
      return 'One or more picklists would exceed the required pick amount. Manual intervention required.';
    case 'PICK_COUNT_MISMATCH':
      return 'The number of items to pick does not match the number required by the assigned picklists. Manual intervention required.';
    case 'PICKLIST_NOT_ASSIGNED_TO_TOTE':
      return 'A pick contained picklists not assigned to a tote location. Manual intervention required.';
    case 'OVER_PICKED_PICKLIST_LINE_ITEM':
      return 'One or more picklists currently has one or more items with a quantityPicked greater than its quantityToPick. This is likely a system error. Manual intervention required.';
    case 'CONVEYOR_TOTES_NOT_MATCHING_TOTE_LOCATIONS':
      return 'One or more totes have been placed in an incorrect location. Please contact your supervisor for further instructions for manual intervention.';
    case 'TASK_GROUP_ASSIGNED_WITH_INSUFFICIENT_STOCK':
      return 'A task group was assigned to the port while there was insufficient stock to complete the pick.';
    case 'TASK_GROUP_ASSIGNED_WITH_CANCELLED_PICKLISTS':
      return 'The task group contains cancelled picklists.';
    case 'TASK_GROUP_ASSIGNED_WITH_INVALID_FULFILMENT_ORDERS':
      return 'The task group contains cancelled or rejected fulfilment orders.';
    case 'TASK_GROUP_WAS_CANCELLED':
      return 'A task group was assigned to the port while it was cancelled.';
    case 'TASK_GROUP_MISSING_BINS':
      return 'A task group has at least one task missing a bin';
    case 'MISSING_TASK_ID':
      return 'The bin has been opened but no task ID was returned';
    default:
      return isNever(reason);
  }
};
