"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.intermediaryStationCommandOutcomeSchema = exports.intermediaryStationCommandFailedSchema = void 0;
const zod_1 = require("zod");
const generic_1 = require("./generic");
exports.intermediaryStationCommandFailedSchema = zod_1.z.object({
    outcome: zod_1.z.literal('INTERMEDIARY_STATION_COMMAND_FAILED_TO_SEND'),
    stationId: zod_1.z.number(),
    warehouseId: zod_1.z.string(),
});
exports.intermediaryStationCommandOutcomeSchema = zod_1.z.union([
    generic_1.Success,
    exports.intermediaryStationCommandFailedSchema,
]);
