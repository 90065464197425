import { ReactComponent as TroubleshootIcon } from '../../../../../assets/img/icons/troubleshoot-icon.svg';
import { Button } from '../../../../../components/warehouse/Button';
import { PickingInformationProps } from '../PickingInformation';

export const FooterButtons = ({
  troubleshoot,
  detectMissingItem,
  isBarcodeFeatureEnabled,
  isImmediatePushEnabled,
}: {
  troubleshoot: PickingInformationProps['troubleshoot'];
  detectMissingItem: PickingInformationProps['detectMissingItem'];
  isBarcodeFeatureEnabled?: boolean;
  isImmediatePushEnabled?: boolean;
}): React.ReactNode[] => {
  const TroubleshootButton: React.ReactNode = (
    <Button
      variant="warning"
      icon={<TroubleshootIcon />}
      fullWidth
      onClick={troubleshoot}
    >
      Troubleshoot
    </Button>
  );

  const NoItemsFoundButton: React.ReactNode = (
    <Button variant="danger" fullWidth onClick={detectMissingItem}>
      No items found
    </Button>
  );

  // TODO[WMS1694] Temporarily disable this button for immediate push. Apparently operators
  // don't tend to use this when they place red pape in totes anyway
  if (!isBarcodeFeatureEnabled || isImmediatePushEnabled) {
    return [TroubleshootButton];
  }

  return [NoItemsFoundButton, TroubleshootButton];
};
