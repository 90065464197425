import { RetailUnit as RetailUnitType } from 'api-schema/lib';
import { ChangeEvent, ReactElement, useState } from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/img/icons/info-circle.svg';
import { ReactComponent as PutToLightIcon } from '../../../../assets/img/icons/put-to-light.svg';
import { Box } from '../../../../components/warehouse/Box';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { InputField } from '../../../../components/warehouse/InputField';
import {
  PickingStepContent,
  PickingSteps,
} from '../../../../components/warehouse/PickingSteps/PickingSteps';
import { Text } from '../../../../components/warehouse/Text';
import { warehouseColours } from '../../../../constants/colours';
import { FooterButtons } from './components/FooterButtons';
import { Header } from './components/Header';
import * as Elements from './PickingInformation.elements';

import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';

export type PickingInformationLegacyProps = {
  item?: RetailUnitType;
  completePick: () => void;
  triggerShortPick?: () => void;
  troubleshoot?: () => void;
  isShortPick: boolean;
  detectMissingItem?: () => void;
  isBarcodeFeatureEnabled?: boolean;
  showScanBarcodeInstruction?: () => void;
  isImmediatePushEnabled: boolean;
};

export function PickingInformationLegacy({
  item,
  completePick,
  troubleshoot,
  isShortPick,
  detectMissingItem,
  isBarcodeFeatureEnabled = false,
  showScanBarcodeInstruction,
  isImmediatePushEnabled,
}: PickingInformationLegacyProps): ReactElement {
  const [barcode, setBarcode] = useState<string>('');
  const [isBarcodeScannedMatch, setIsBarcodeScannedMatch] =
    useState<boolean>(false);

  const handleBarcodeInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const barcode = e.target.value.trim();
    setBarcode(barcode);
    setIsBarcodeScannedMatch(!!item && !!barcode && barcode === item?.barcode);
  };

  const completePickAndClearBarcode = () => {
    completePick();
    setBarcode('');
    setIsBarcodeScannedMatch(false);
  };
  const scanFirstItemInBinContent: PickingStepContent = {
    completed: barcode && item ? barcode === item.barcode : false,
    withCount: true,
    stepTitle: (
      <>
        Scan first item in bin partition
        <InfoIcon
          onClick={showScanBarcodeInstruction}
          fill={warehouseColours.info.dark}
        />
      </>
    ),
    content: (
      <InputField
        placeholderText={'Scan item barcode'}
        hasError={!!barcode && barcode !== item?.barcode}
        isValid={!!barcode && barcode === item?.barcode}
        value={barcode}
        onChange={handleBarcodeInputChange}
        id="item-barcode"
        fullWidth
        autoFocus
        disabled={!!barcode && barcode === item?.barcode}
      />
    ),
    errorContent:
      !isBarcodeScannedMatch && barcode
        ? 'Barcode scanned does not match the order item. Scan again or enter manually'
        : null,
  };

  const placeItemsInTotesContent: PickingStepContent = {
    completed: false,
    withCount: true,
    stepTitle: 'Place items in corresponding totes',
    content: (
      <Button
        variant="primary"
        onClick={completePickAndClearBarcode}
        testId="pick-complete-button"
        fullWidth={true}
      >
        {isShortPick ? 'Short pick complete' : 'Pick complete'}
      </Button>
    ),
    errorContent: null,
  };

  const placeItemsInTotesPtlContent: PickingStepContent = {
    stepTitle: 'Place items in corresponding totes',
    content: (
      <Box paddingLeft="2.7rem">
        <AlertMessage type={AlertMessageTypes.Light}>
          <Box marginRight="1rem">
            <PutToLightIcon width="80" height="auto" />
          </Box>
          <Text>
            Press the <strong>pick confirmation button</strong> for each tote
            after placing the items
          </Text>
        </AlertMessage>
      </Box>
    ),
  };

  const placeItemsInTotesStep = isImmediatePushEnabled
    ? placeItemsInTotesPtlContent
    : placeItemsInTotesContent;

  return (
    <Container
      buttons={FooterButtons({
        troubleshoot,
        detectMissingItem,
        isBarcodeFeatureEnabled,
        isImmediatePushEnabled,
      })}
      padding="none"
      alignCenter
    >
      <Elements.Wrapper>
        <Header isShortPick={isShortPick} item={item} />
        {isBarcodeFeatureEnabled ? (
          <>
            <PickingSteps
              stepContents={
                isBarcodeScannedMatch && barcode
                  ? [scanFirstItemInBinContent, placeItemsInTotesStep]
                  : [scanFirstItemInBinContent]
              }
            ></PickingSteps>
          </>
        ) : (
          <Elements.Body>
            <Text variant="body1" weight="medium">
              Place items in corresponding totes
            </Text>
            <Button
              variant="primary"
              onClick={completePick}
              testId="pick-complete-button"
            >
              {isShortPick ? 'Short pick complete' : 'Pick complete'}
            </Button>
          </Elements.Body>
        )}
      </Elements.Wrapper>
    </Container>
  );
}
